import React from "react";

import { withTranslate } from "../../components/translate";
import Dockington from "../../components/common/Dockington";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import NavBar from "../../components/common/NavBar";
import Jumbotron from '../../components/common/Jumbotron';
import JumboDivider from '../../components/common/JumboDivider';
import SEO from "../../components/SEO";

import aurora from "../../assets/images/aurora.png";
import mauro from "../../assets/images/mauro.png";
import biancoline from "../../assets/images/biancoline.png";

import processText from "../processText";

import "./TestimonialsPage.css";
import "../../components/common/Review.css";

const reduceParagraphs = function (list, part) {
    if (Array.isArray(part)) {
        return list.concat(part.reduce(reduceParagraphs, []));
    } else {
        if (part.b) {
            return list.concat([<b key={part.b}>{part.b}</b>]);
        } else if (part.highlight) {
            return list.concat([<span key={part.highlight} className="highlight">{part.highlight}</span>]);
        } else {
            return list.concat([part]);
        }
    }
}

const FullReview = function ({ review, image, imageAlt }) {
    return (
        <Row className="testimonial pt-3 pb-5">
            <Col xs12 className="full-review-image-container">
                <a target="_blank" href={review.website}>
                    <img
                        className="review-image"
                        src={image}
                        alt={imageAlt}
                    />
                </a>
            </Col>
            <Col xs12 l>
                <h2 id={review.id} className="quote pt-2">&ldquo;{review.quote.map((line) => line.map((piece, i) => (typeof piece === "string" ? piece : piece.emph)))}&rdquo;</h2>
                {review.full.map((part, i) => (
                    <p key={i}>{reduceParagraphs([], part)}</p>
                ))}
                <div className="quote-author">
                    <p className="quote-author-name">{review.author.name}</p>
                    <p className="quote-author-title">{processText(review.author.titleWithLink)}</p>
                </div>
            </Col>    
        </Row>
    )
}

const TestimonialsPage = withTranslate(function ({ translate }) {
    return (
        <div className="testimonials-page">
            <SEO
                title={translate("testimonials.head.title")}
                description={translate("testimonials.head.description")}
                path="/testimonials"
            />
            <ScrollToTopOnMount />
            <NavBar translate={translate} />
            <header className="container py-3 mt-4 mb-5">
                <div className="intro mt-3">
                    <h1>
                        {translate("testimonials.tagline.title")}
                    </h1>
                    <p className="h2-sub">
                        {translate("testimonials.tagline.subtitle").map((part) => (part.dockington ? <Dockington /> : part))}
                    </p>
                </div>
            </header>
            <JumboDivider color="#FFC107"/>
            <Jumbotron className="py-0" color="#fff">
                <FullReview review={translate("testimonials.biancoline")} image={biancoline} imageAlt="Bianco-Line Pet - Fausto" />
            </Jumbotron>
            <Jumbotron className="py-0 divider mb-3" color="#fff">
                <FullReview review={translate("testimonials.aurora")} image={aurora} imageAlt="O.M.M. - Aurora" />
            </Jumbotron>
            <Jumbotron className="py-0 divider mb-3" color="#fff">
                <FullReview review={translate("testimonials.mauro")} image={mauro} imageAlt="O.M.M. - Mauro" />
            </Jumbotron>
        </div>
    )
});

export default TestimonialsPage;
